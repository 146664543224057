import React, {Component} from 'react';
import Tiles from './Tiles'
import NavBar from "./NavBar";
import Footers from "./Footers";
import './styles/Home.sass'

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            usersFlag: false
        };
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <p className='margin-text'>
                                <span className='text-red'><b>OUTCITE</b></span>, the extension of <a className='wrap'
                                                                                                      target='_blank'
                                                                                                      rel='noreferrer'
                                                                                                      href='https://excite.informatik.uni-stuttgart.de/'>EXCITE</a>
                                &nbsp;(Extraction of Citations from PDF Documents) is a toolchain of citation extraction
                                software and particular focus on the social sciences
                                and this is a public service for the project. In the background of this page we are
                                using <a className='wrap' target='_blank' rel='noreferrer'
                                         href='https://github.com/kermitt2/grobid'>GROBID</a> and <a className='wrap'
                                                                                                     target='_blank'
                                                                                                     rel='noreferrer'
                                                                                                     href='https://github.com/CeON/CERMINE'>CERMINE</a> for
                                extracting content from PDF files. Afterwards, <a className='wrap' target='_blank'
                                                                                  rel='noreferrer'
                                                                                  href='https://github.com/inukshuk/anystyle-cli'>Anystyle</a> and <a
                                className='wrap' target='_blank' rel='noreferrer'
                                href='https://github.com/exciteproject/Exparser'>Exparser</a> has been used along with
                                self processing of XML from
                                GROBID and CERMINE for references extraction and segmentation.
                            </p>
                            <div className='row d-flex justify-content-center'>
                                <div className='col-6'>
                                    <ul className='margin-text'><u>How to start the process:</u>
                                        <li><b>First:</b> Choose a pdf file by clicking on "Choose File" button.</li>
                                        <li><b>Second:</b> Click on "Upload" button to start the process.</li>
                                        <li><b>Note:</b> After uploading a file, follow-up ID will be displayed on the
                                            screen. This ID is necessary for following up the results for an upload.
                                            So, kindly save it or bookmark a URL.
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-6'>
                                    <ul className='margin-text'><u>How to check the result:</u>
                                        <li><b>First:</b> Enter the follow-up ID in the search bar below then click on
                                            "Search" button or
                                            directly hit the URL(displayed for the new upload) to open it up in the new
                                            tab.
                                        </li>
                                        <li><b>Second:</b> The search result will be displayed below on the same screen
                                            if it is available but you
                                            can also navigate to the new screen with "Get to Result" button.
                                        </li>
                                        <li><b>Note:</b> Complete extraction process will take a while,
                                            at least a minute for new upload, and it completely depends on the size of
                                            the
                                            file.
                                        </li>
                                        <li><b>Tip:</b> If you ever forget to save the follow-up ID or bookmark the
                                            result URL
                                            then you can also search for the results for your upload via searching for
                                            some unique keywords
                                            or strings like title, author(s) etc. of the pdf.
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-10'>
                                    <em className='text-red'>Disclaimer:</em> <strong><i>Do not upload any confidential
                                    or private document that you
                                    don't want to share publicly. Result will be accessible to all users.</i></strong>
                                    <br/><br/>
                                    <div className='text-center'>
                                        <strong>Processed Document Example: </strong>
                                        <a href="https://demo-outcite.gesis.org/users/b66f4abceff160ddc6e77bc005aa8cc6"
                                            target='_blank'
                                            rel='noreferrer'>
                                            {"https://demo-outcite.gesis.org/users/b66f4abceff160ddc6e77bc005aa8cc6"}
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            {
                                <NavBar datasets={['USERS']} activate={
                                    (uFlag) => {
                                        this.setState({
                                            usersFlag: uFlag
                                        })
                                    }}
                                        indices={['users']}
                                        getIdIndex={this.props.getIdIndex}
                                />
                            }
                        </div>
                    </div>
                </div>
                {/*<div className='row'>*/}
                {/*    {*/}
                {/*        this.state.usersFlag ?*/}
                {/*            <Tiles title={'USERS'} tools={['Grobid', 'Cermine', 'Anystyle']}*/}
                {/*                   index={'users'} getIdIndex={this.props.getIdIndex}/>*/}
                {/*            :*/}
                {/*            null*/}
                {/*    }*/}
                {/*</div>*/}
                <div className='row'>
                    <div className='col-12'>
                        <Footers/>
                    </div>
                </div>
            </>
        );
    }
}

export default Home;
